<template>
  <div
    class="cursor-pointer"
  >
    <div
      class="rounded-lg gap-1 pl-2"
      :class="`bg-${issueType}`"
    >
      <div
        v-if="!selectedCard"
        class="bg-white rounded-r-lg gap-1 p-6"
      >
        <slot />
      </div>

      <div
        v-else
        class=" rounded-r-lg gap-1 p-6"
        :class="`bg-${issueType}`"
      >
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    issueType: {
      type: String,
      default: "critical-issue",
    },
    selected: {
      type: Boolean,
      default: false,
    }
  },

  computed: {
    selectedCard() {
      return this.selected;
    }
  }
}
</script>

<style>

</style>