<template>
  <div class="grid grid-cols-4 gap-4">
    <IssueTypeCard
      v-for="(item, index) in issueCardFlags"
      :key="9392+index"
      :selected="item.flag"
      :issue-type="item.name"
      @click="handleIssueCardSelected(item)"
    >
      <template #default>
        <div class="flex flex-col gap-1 justify-center w-full h-full">
          <span
            class="text-sm font-semibold"
            :class="item.flag ? 'text-white' : ''"
            v-text="item.text"
          />
          <span
            class="text-xs"
            :class="item.flag ? 'text-gray-100' : 'text-gray-500'"
          >
            {{ 
              item.value > 0
                ? `${item.value} ${item.text} Issues`
                : '-'
            }}
          </span>
        </div>
      </template>
    </IssueTypeCard>

    <div
      v-if="!hasFilter"
      class="col-span-4"
    >
      <DataTable />
    </div>

    <div
      v-else
      class="col-span-4"
    >
      <div class="flex w-full border border-gray-200 rounded-t py-4 px-10 mb-14">
        <div class="flex">
          filtreler gelecek select şeklinde
        </div>
      </div>

      <Card>
        <template #content>
          <div class="flex flex-row gap-5 w-full h-full">
            <div class="flex items-center">
              <ExclamationCircleIcon 
                class="h-5 w-5 text-critical-issue"
                aria-hidden="true"
              />
            </div>

            <div class="flex flex-col w-full">
              <span class="text-base font-normal">
                Untrusted TLS Certificate
              </span>

              <span class="text-xs font-light">
                http://ron.digital
              </span>
            </div>
          </div>
        </template>
      </Card>
    </div>
  </div>
</template>

<script>
import IssueTypeCard from '@/components/IssueTypeCard.vue'
import DataTable from '@/components/DataTable.vue'
import Card from '@/components/Card.vue'
import { ExclamationCircleIcon } from '@heroicons/vue/solid'

export default {
  components: {
    IssueTypeCard,
    DataTable,
    Card,
    ExclamationCircleIcon,
  },

  data: () => ({
    hasFilter: false,
    issueCardFlags: {
      critical: {
        name: 'critical-issue',
        flag: false,
        text: 'Critical',
        value: 3,
      },
      high: {
        name: 'high-issue',
        flag: false,
        text: 'High',
        value: 0,
      },
      medium: {
        name: 'medium-issue',
        flag: false,
        text: 'Medium',
        value: 0,
      },
      low: {
        name: 'low-issue',
        flag: false,
        text: 'Low',
        value: 3,
      },
    }
  }),

  methods : {
    handleIssueCardSelected(issueCard) {
      for(let cardFlag in this.issueCardFlags) {
        if (issueCard.name === this.issueCardFlags[cardFlag].name) {
          if (this.issueCardFlags[cardFlag].flag === true) {
            this.issueCardFlags[cardFlag].flag = false;
            this.hasFilter = false;
          } else {
            this.hasFilter = true;
            this.issueCardFlags[cardFlag].flag = true;
          }
        } else {
          this.issueCardFlags[cardFlag].flag = false;
        }
      }
    }
  }
}
</script>

<style>

</style>