<template>
  <div class="px-4 sm:px-6 lg:px-8">
    <div class="mt-8 flex flex-col">
      <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full align-middle">
          <div class="overflow-hidden md:rounded-lg">
            <table class="min-w-full divide-y divide-gray-300">
              <thead class="bg-white">
                <tr>
                  <th>
                    <span class="sr-only" />
                  </th>

                  <th
                    v-for="(header, index) in tableData.headers"
                    :key="index"
                    scope="col"
                    class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 items-center"
                  >
                    <span
                      class="group inline-flex text-gray-400 text-xs items-center w-30"
                    >
                      {{ header }}
                    </span>
                  </th>
                </tr>
              </thead>

              <tbody class="divide-y divide-gray-200 bg-white w-full">
                <tr
                  v-for="(data, rowIndex) in tableData.data"
                  :key="rowIndex"
                >
                  <td class="whitespace-nowrap text-sm font-medium text-gray-900 pl-6">
                    <CheckCircleIcon class="w-5 h-5" />
                  </td>

                  <td
                    v-for="(value, dataIndex) in data"
                    :key="dataIndex"
                    class="py-4 px-4 text-sm font-medium text-gray-900 sm:pl-6"
                  >
                    <div class="w-full line-clamp-2">
                      {{ value }}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CheckCircleIcon } from '@heroicons/vue/outline'

export default {
  components: {
    CheckCircleIcon
  },

  data: () => ({
    tableData: {
      headers: [
        'TASK NAME', 'PROJECT', 'DUE DATE', 'PRIORITY', 'STATUS'
      ],
      data: [
        ['Updated payment modules based on Accessibility assessment', 'Revamp Design System based on new vision', '7/01/2019', 'Low', 'Completed'],
        ['Updated payment modules based on Accessibility assessment', 'Revamp Design System based on new vision', '7/01/2019', 'Low', 'Completed']
      ]
    }
  }),
}
</script>